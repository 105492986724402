import { TENANT } from '../../tenant/tenantTypes'

export const EXPERIMENT_EXIT_INTENT_POPUP = {
  [TENANT.AT]: { desktop: 'cro-3105', mobile: 'cro-3104' },
  [TENANT.CH]: { desktop: 'cro-3110', mobile: 'cro-3111' },
  [TENANT.COM]: { desktop: 'cro-3102', mobile: 'cro-3103' },
  [TENANT.FR]: { desktop: 'cro-3109', mobile: 'cro-3108' },
  [TENANT.IT]: { desktop: 'cro-3106', mobile: 'cro-3107' },
}

export const EXPERIMENT_BREADCRUMBS = {
  [TENANT.AT]: { desktop: 'cro-2641', mobile: 'cro-2646' },
  [TENANT.BE]: { desktop: 'cro-3834', mobile: 'cro-3834-m' },
  [TENANT.CH]: { desktop: 'cro-2644', mobile: 'cro-2649' },
  [TENANT.COM]: { desktop: 'cro-2640', mobile: 'cro-2645' },
  [TENANT.FR]: { desktop: 'cro-2643', mobile: 'cro-2648' },
  [TENANT.IT]: { desktop: 'cro-2642', mobile: 'cro-2647' },
}

export const EXPERIMENT_NAME_FREE_GIFT_PROMOTION = {
  [TENANT.AT]: 'cro-3279',
  [TENANT.BE]: 'cro-3856',
  [TENANT.CH]: 'cro-3282',
  [TENANT.COM]: 'cro-3278',
  [TENANT.FR]: 'cro-3281',
  [TENANT.IT]: 'cro-3280',
}

export const EXPERIMENT_NAME_STICKY_BUY_BOX = {
  [TENANT.AT]: 'cro-3301',
  [TENANT.BE]: 'cro-3302',
  [TENANT.CH]: 'cro-3305',
  [TENANT.COM]: 'cro-3300',
  [TENANT.FR]: 'cro-3304',
  [TENANT.IT]: 'cro-3303',
}

export const EXPERIMENT_AFTER_QUERY_SUGGEST_LAYER = {
  [TENANT.AT]: 'cro-3885',
  [TENANT.BE]: 'cro-3886',
  [TENANT.CH]: 'cro-3889',
  [TENANT.COM]: 'cro-3883',
  [TENANT.FR]: 'cro-3888',
  [TENANT.IT]: 'cro-3887',
}

export const EXPERIMENT_NEWSLETTER_MODAL = {
  [TENANT.CH]: {
    desktop: 'cro-4137',
    mobile: 'cro-4137',
  },
  [TENANT.IT]: {
    desktop: 'cro-3970',
    mobile: 'cro-3970',
  },
}

export const EXPERIMENT_OWN_BRANDS_OVERLAY = {
  [TENANT.AT]: 'cro-3777',
  [TENANT.IT]: 'cro-3778',
}

export const EXPERIMENT_NAME_REDPOINTS_POPUP_IT = {
  [TENANT.IT]: 'cro-4254',
}

export const EXPERIMENT_NAME_REMOVE_PACKSIZES_FOR_EXPA = {
  [TENANT.IT]: { desktop: 'cro-4364', mobile: 'cro-4364' },
}
